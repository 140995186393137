<template>
  <q-dialog
    v-model="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
    style="background: rgba(0, 0, 0, 0)"
    :maximized="$q.platform.is.mobile ? maximizedToggle : false"
    :style="{ 'font-family': settings.typography }"
  >
    <q-card class="q-pa-md">
      <CloseButton />
      <q-card class="my-card text-left q-ma-md">
        <h6>{{ $t("FAQ.title") }}</h6>
        <q-list class="q-mb-md">
          <p class="text-weight-bold">{{ $t("FAQ.title-about-app") }}</p>
          <q-expansion-item expand-separator :label="$t('FAQ.app-question-1')">
            <q-card>
              <q-card-section>
                {{ $t("FAQ.app-answer-1") }}
              </q-card-section>
            </q-card>
          </q-expansion-item>
          <q-expansion-item expand-separator :label="$t('FAQ.app-question-2')">
            <q-card>
              <q-card-section>
                {{ $t("FAQ.app-answer-2") }}
              </q-card-section>
            </q-card>
          </q-expansion-item>
          <q-expansion-item expand-separator :label="$t('FAQ.app-question-3')">
            <q-card>
              <q-card-section>
                {{ $t("FAQ.app-answer-3") }}
              </q-card-section>
            </q-card>
          </q-expansion-item>
          <q-expansion-item expand-separator :label="$t('FAQ.app-question-4')">
            <q-card>
              <q-card-section>
                {{ $t("FAQ.app-answer-4") }}
              </q-card-section>
            </q-card>
          </q-expansion-item>
          <q-expansion-item expand-separator :label="$t('FAQ.app-question-5')">
            <q-card>
              <q-card-section> {{ $t("FAQ.app-answer-5") }} </q-card-section>
            </q-card>
          </q-expansion-item>
        </q-list>

        <q-list class="q-mb-md">
          <p class="text-weight-bold">{{ $t("FAQ.title-about-login") }}</p>
          <q-expansion-item
            expand-separator
            :label="$t('FAQ.login-question-1')"
          >
            <q-card>
              <q-card-section>
                {{ $t("FAQ.login-answer-1") }}
              </q-card-section>
            </q-card>
          </q-expansion-item>
          <q-expansion-item
            expand-separator
            :label="$t('FAQ.login-question-2')"
          >
            <q-card>
              <q-card-section>
                {{ $t("FAQ.login-answer-2") }}
              </q-card-section>
            </q-card>
          </q-expansion-item>
          <q-expansion-item
            expand-separator
            :label="$t('FAQ.login-question-3')"
          >
            <q-card>
              <q-card-section>
                {{ $t("FAQ.login-answer-3") }}
              </q-card-section>
            </q-card>
          </q-expansion-item>
          <q-expansion-item
            expand-separator
            :label="$t('FAQ.login-question-4')"
          >
            <q-card>
              <q-card-section>
                {{ $t("FAQ.login-answer-4") }}
              </q-card-section>
            </q-card>
          </q-expansion-item>
        </q-list>

        <q-list class="q-mb-md">
          <p class="text-weight-bold">{{ $t("FAQ.title-about-buying") }}</p>
          <q-expansion-item
            expand-separator
            :label="$t('FAQ.buying-question-1')"
          >
            <q-card>
              <q-card-section>
                {{ $t("FAQ.buying-answer-1") }}
              </q-card-section>
            </q-card>
          </q-expansion-item>
          <q-expansion-item
            expand-separator
            :label="$t('FAQ.buying-question-2')"
          >
            <q-card>
              <q-card-section>
                {{ $t("FAQ.buying-answer-2") }}
              </q-card-section>
            </q-card>
          </q-expansion-item>
          <q-expansion-item
            expand-separator
            :label="$t('FAQ.buying-question-3')"
          >
            <q-card>
              <q-card-section>
                {{ $t("FAQ.buying-answer-3") }}
              </q-card-section>
            </q-card>
          </q-expansion-item>
          <q-expansion-item
            expand-separator
            :label="$t('FAQ.buying-question-4')"
          >
            <q-card>
              <q-card-section>
                {{ $t("FAQ.buying-answer-4") }}
              </q-card-section>
            </q-card>
          </q-expansion-item>
        </q-list>
      </q-card>
    </q-card>
  </q-dialog>
</template>
<script>
import { ref } from "vue";
import { defineAsyncComponent } from "vue";
import settings from "@/mixins/settings";

export default {
  mixins: [settings],

  components: {
    CloseButton: defineAsyncComponent(() =>
      import("../buttons/CloseButton.vue")
    ),
  },
  props: {
    dialog: {
      default: false,
    },
  },
  setup() {
    return {
      dialog: ref(false),
      maximizedToggle: true,
    };
  },
  
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~quasar-variables";

@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    max-width: 560px;
    position: relative;
    left: 26%;
    top: 24px;
    min-width: 550px;
    min-height: 490px;
  }
}

.q-card {
  background-color: $gray;
}
</style>
